import { Atom, SHARED_VALIDATIONS, helper } from ":mods";
import { LOCATIONS_LIST } from "../const";

export function updateProfileInputs() {
  const ProfileImage = Atom.Form.createInputFile(null, {
    required: false,
  });
  const FirstName = Atom.Form.createInput("" as string, {
    required: false,
    validators: (value: string) => {
      const err = SHARED_VALIDATIONS.person_name.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const LastName = Atom.Form.createInput("" as string, {
    required: false,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.person_name.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Gender = Atom.Form.createSelect("" as string, {
    required: false,
  });
  const DOB = Atom.Form.createInput("" as string, {
    required: false,
  });

  const Email = Atom.Form.createInput("" as string, {
    required: false,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.email.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const MobileNo = Atom.Form.createPhoneInput("" as string, {
    required: false,
  });
  const CountryCode = Atom.Form.createInput("" as string, {
    required: false,
  });
  const Country = Atom.Form.createSelect("" as string, {
    required: false,
  });
  const Region = Atom.Form.createSelect("" as string, {
    required: false,
  });

  const Actions = Atom.Form.createFormActions(
    {
      ProfileImage,
      FirstName,
      LastName,
      Email,
      Gender,
      DOB,
      MobileNo,
      CountryCode,
      Country,
      Region,
    },
    {
      defaultCase: "snake",
    }
  );
  return {
    Actions,
    ProfileImage,
    FirstName,
    LastName,
    Email,
    Gender,
    DOB,
    MobileNo,
    CountryCode,
    Country,
    Region,
  };
}
